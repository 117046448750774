@use 'styles/colors' as *;

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
}

h1 {
  margin: 0 0 24px 0;
  font-size: 20px;
  text-align: center;
  color: $dark-black;
}

h2 {
  margin: 0 0 24px 0;
  font-size: 18px;
  text-align: center;
  color: $dark-black;
}

a {
  text-decoration: none;
  color: $lighter-purple;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: none;
  }
}

button {
  position: relative;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  background: $purple;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 $light-purple;

  &.small {
    padding: 6px 12px;
    font-size: 14px;
  }

  &:focus-visible {
    outline: none;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: $lighter-purple;
    }
  }

  &.secondary {
    background: $light-black;

    &:not(:disabled) {
      &:hover,
      &:focus-visible {
        background-color: $lighter-black;
      }
    }
  }

  &.dangerous {
    background: $red;

    &:not(:disabled) {
      &:hover,
      &:focus-visible {
        background-color: $lighter-red;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  .pending {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .action-error {
    align-self: center;
    text-align: center;
  }

  .form-wrapper {
    display: flex;
    gap: 16px;

    input {
      padding: 15px 24px;
      font-size: 16px;
      text-align: center;
      color: $dark-black;
      background-color: $white;
      border: solid 1px $medium-purple;
      border-radius: 4px;
      box-shadow: 0 0 8px 0 $light-purple;

      &:disabled {
        opacity: 0.5;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: inherit;
        opacity: 0.65;
      }

      &.error {
        color: $red;
        border-color: $red;
      }
    }
  }

  .form-actions {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
}

@media ( max-width: 800px ) {
  form {
    align-items: center;

    .form-wrapper {
      flex-direction: column;
    }
  }
}

.message {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: $black;
  text-align: center;

  p {
    margin: 0;

    & + p {
      margin-top: 12px;
    }
  }
}

.loading-error {
  font-size: 16px;
  color: $red;
}

.action-error {
  font-size: 14px;
  color: $red;
}
