$purple: #6d4194;
$lighter-purple: #8d5cb7;
$medium-purple: #a57cc9;
$light-purple: #f3edf7;
$red: #c92625;
$lighter-red: #de2321;
$dark-gray: #808080;
$gray: #e0e0e0;
$lightish-gray: #f0f0f0;
$light-gray: #fafafa;
$lighter-black: #808080;
$light-black: #606060;
$black: #404040;
$dark-black: #202020;
$white: #ffffff;
